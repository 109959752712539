import React, { useEffect, useMemo, useState } from 'react';
import { Page } from '~/src/components/Page';
import { useMfa } from '~/src/hooks/useMfa';
import { generate as qrGenerate } from 'lean-qr';
import {
  Box,
  Divider,
  LinearProgress,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Form, Button } from '~/src/components/styled';

const useStyles = makeStyles(() => ({
  mfa: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '500px',
    textAlign: 'center',
  },
  flexDivider: {
    width: '100%',
    margin: 12,
  },
  showCode: {
    fontSize: '0.8rem',
    padding: '4px',
    borderRadius: '4px',
    textAlign: 'center',
    background: 'rgba(0, 0, 0, 0.1)',
    whiteSpace: 'pre-wrap',
  },
  verification: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: '12px',
  },
}));

export function MfaPage() {
  const classes = useStyles();
  const [qrImageData, setQrImageData] = useState<string>('');
  const [showCode, setShowCode] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const { state, message } = useMemo(() => {
    const sp = new URLSearchParams(window.location.search);
    const base64 = sp.get('state');
    const message = sp.get('message');
    if (base64) {
      try {
        return { state: JSON.parse(window.atob(base64)), message };
      } catch (_) {
        return { state: {}, message };
      }
    }
    return { state: {}, message };
  }, [window.location.search]);

  const { secretCode, error, loading, userData, session } = useMfa(state);

  useEffect(() => {
    if (!state.accountId) {
      // jump out if accountId is not specified
      document.location.assign(`/`);
    }
    if (secretCode) {
      const email = userData?.UserAttributes.find(
        (ua) => ua.Name === 'email',
      )?.Value;
      setQrImageData(
        qrGenerate(
          `otpauth://totp/JupiterOne${
            email ? `:${email}` : ''
          }?secret=${secretCode}&issuer=JupiterOne`,
        ).toDataURL({
          scale: 5,
          // default is transparent, force white
          // just in case users are using browser dark modes
          off: [255, 255, 255],
        }),
      );
    }
  }, [secretCode]);

  if (loading)
    return (
      <Page title="">
        <LinearProgress />
      </Page>
    );

  return (
    <Page title={loading ? '' : 'MFA Required'} centerTitle>
      <Box className={classes.mfa}>
        {error ? (
          <b>{error.message}</b>
        ) : qrImageData ? (
          <>
            Enroll an Authenticator app to continue
            <img src={qrImageData} />
            {showCode ? (
              <Box>
                Enter this code into your Authenticator app:
                <pre className={classes.showCode}>{secretCode}</pre>
              </Box>
            ) : (
              <a href="#" onClick={() => setShowCode(true)}>
                Can't scan?
              </a>
            )}
            <Divider className={classes.flexDivider} />
          </>
        ) : null}
        {!loading && (
          <Form
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();
              const sp = new URLSearchParams(window.location.search);
              sp.set('verificationCode', verificationCode);
              sp.set('accessToken', session!.getAccessToken().getJwtToken());
              document.location.assign(
                `/account/${state.accountId}?${sp.toString()}`,
              );
            }}
          >
            <Typography variant="h5">Enter your verification code:</Typography>
            <Box className={classes.verification}>
              <TextField
                label="Verification code"
                placeholder="Enter code"
                onChange={(e) => setVerificationCode(e.target.value)}
                inputProps={{ maxLength: 6 }}
              />
              <Button
                disabled={!verificationCode || verificationCode.length != 6}
                fullWidth={false}
              >
                Confirm
              </Button>
            </Box>
            {message && !verificationCode && (
              <Typography color="error">{message}</Typography>
            )}
          </Form>
        )}
      </Box>
    </Page>
  );
}

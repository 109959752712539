import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useAccounts } from '~/src/hooks/useAccounts';
import { useInvitations } from '~/src/hooks/useInvitations';
import { Page } from '../../components/Page';
import { Invitations } from './Invitations';
import { CreateAccount } from './NoAccess';
export function AccountlessPage() {
  const {
    invitations,
    loading: loadingInvitations,
    getInvitations,
    updateInvitation,
    updated,
    error,
  } = useInvitations();
  const { accounts, getAccounts, loading: loadingAccounts } = useAccounts();
  const hasInvitations = invitations.length > 0;
  const [invitationsSeen, setInvitationsSeen] = React.useState(0);

  const loading = loadingInvitations || loadingAccounts;

  useEffect(() => {
    if (accounts.length > 0 && !loadingInvitations && !hasInvitations) {
      document.location = '/account-selector?destination=/welcome/paths';
    }
  }, [accounts, loadingInvitations, hasInvitations]);

  useEffect(() => {
    getInvitations();
    getAccounts();
  }, []);

  useEffect(() => {
    if (invitationsSeen === 0 && invitations.length > 0)
      setInvitationsSeen(invitations.length);
    if (invitationsSeen > 0 && invitations.length < invitationsSeen) {
      getAccounts();
    }
  }, [invitations.length, invitationsSeen]);

  const title = hasInvitations ? 'Invitations' : 'Welcome!';

  return (
    <Page title={title} centerTitle={true} showRightColumn={false}>
      {!loading && hasInvitations && (
        <Invitations
          updated={updated}
          error={error}
          loading={loading}
          updateInvitation={updateInvitation}
          invitations={invitations}
          getInvitations={getInvitations}
        />
      )}
      {!loading && !hasInvitations && accounts.length === 0 && (
        <CreateAccount />
      )}
      {loading && <CircularProgress />}
    </Page>
  );
}
